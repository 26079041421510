<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          <span>
            {{ title }}
          </span>
        </h5>

        <b-form-group
          id="input-group-name"
          label="Nama:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            placeholder="Nama"
          ></b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-capacity"
          label="Kapasitas:"
          label-for="input-capacity"
        >
          <b-form-input
            id="input-capacity"
            v-model="form.capacity"
            placeholder="Kapasitas"
          ></b-form-input>
          <small class="text-danger">{{ error.capacity }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-buildings"
          label="Gedung:"
          label-for="input-buildings"
        >
          <treeselect
            v-model="form.building_id"
            :multiple="false"
            placeholder="Pilih Gedung"
            :options="buildings"
          />
          <small class="text-danger">{{ error.building_id }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-room-type-id"
          label=""
          label-for="input-room-type-id"
        >
          <b-form-checkbox
            v-model="form.room_type_id"
            name="check-button"
            switch
          >
            Ruangan Lab ? <b>({{ form.room_type_id ? "Ya" : "Bukan" }})</b>
          </b-form-checkbox>
          <small class="text-danger">{{ error.room_type_id }}</small>
        </b-form-group>

        <b-form-group id="input-group-description">
          <label for="input-description"
            >Keterangan: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-description"
            v-model="form.description"
            placeholder="Keterangan"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.description }}</small>
        </b-form-group>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/masters/rooms/list')"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  data() {
    return {
      error: {
        name: "",
        description: "",
        capacity: "",
        building_id: "",
      },
      buildings: [],
      room_types: [{ label: "Kelas", value: 0 }, { label: "" }],
    };
  },
  methods: {
    async getBuildingOption() {
      let response = await module.setTreeSelect("api/buildings");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.buildings = response.data;
        this.buildings.unshift({
          label: "Pilih Gedung",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      if (this.form.room_type_id) {
        this.form.room_type_id = 1;
      } else {
        this.form.room_type_id = 0;
      }
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );

        if (this.form.room_type_id) {
          this.form.room_type_id = true;
        } else {
          this.form.room_type_id = false;
        }
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$router.push("/masters/rooms/list");
      }
    },
  },
  mounted() {
    this.getBuildingOption();
  },
};
</script>